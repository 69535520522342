import InputWithLabel from "./InputWithLabel"
import styled from "styled-components"
import { useState } from "react"
import Icon from "../Icon"

const IconContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  margin-left: calc(100% - 8vw - 40px);
  margin-top: 14px;
  cursor: pointer;

  &:active {
    transform: scale(.9);
  }
`

export default function PasswordInputWithLabel({ label, width, value, onInput, isError }: { label: string, width: string, value: string, onInput: (value: string) => void, isError?: boolean }) {
  const [isHidePassword, setIsHidePassword] = useState<boolean>(true)
  
  return (
    <>
      <IconContainer onClick={() => setIsHidePassword(!isHidePassword)}>
        <Icon path={isHidePassword ? "login.eye" : "login.eyeDisabled"} color="gray_600" />
      </IconContainer>
      <InputWithLabel value={value} onInput={onInput} label={label} width={width} isPassword={isHidePassword} isError={isError} />
    </>
  )
}
