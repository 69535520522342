import styled from "styled-components"

const Container = styled.div<{ width: string, $isError?: boolean }>`
  width: ${props => props.width};
  border: 1px solid ${props => props.$isError ? props.theme.colors.danger : props.theme.colors.gray_300};
  border-radius: 6px;
`

const Label = styled.span<{ $isError?: boolean }>`
  font-weight: 700;
  font-size: 10px;
  margin-top: 7px;
  margin-left: 12px;
  color: ${props => props.$isError ? props.theme.colors.danger : props.theme.colors.gray_500};
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: 600;
  width: 90%;
  outline: none;
  margin-left: 12px;
  margin-bottom: 7px;
  color: ${props => props.theme.colors.gray_900};
`

export default function InputWithLabel({ label, width, value, onInput, isPassword, isError, autoFocus }: { label: string, width: string, value: string, onInput: (value: string) => void, isPassword?: boolean, isError?: boolean, autoFocus?: boolean }) {
  return (
    <Container width={width} $isError={isError}>
      <Label $isError={isError}>{label}</Label>
      <Input autoFocus={autoFocus} type={isPassword ? 'password' : 'text'} value={value} onInput={(e) => onInput((e.target as HTMLInputElement).value)} />
    </Container>
  )
}