import { getAuth } from "firebase/auth"
import fbConf from "./FirebaseConf"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function Comm(endpoint: string, options: {[key: string]: any}) {

  endpoint = endpoint.replace(`/${endpoint.split('/')[1]}`, () => {
    switch (endpoint.split('/')[1]) {
      case 'api-admin':
        return 'https://api.admin.voltie.hu'
      case 'api-charger':
        return 'https://api.charger.voltie.hu'
      case 'api-log':
        return 'https://api.log.voltie.hu'
      case 'api-cdr':
        return 'https://api.cdr.voltie.hu'
      case 'api-notification':
        return 'https://api.notification.voltie.hu'
      case 'api-websocket':
        return 'https://api.websocket.voltie.hu'
      case 'api-firmware':
        return 'https://api.firmware.voltie.hu'
      default:
        return ''
    }
  })

  if (!options.headers) {
    options.headers = {}
  }

  if (!options.headers.Authorization) {
    options.headers.Authorization = localStorage.getItem('token') || ''
  }

  options.headers['Access-Control-Allow-Origin'] = '*'
  options.headers['Access-Control-Allow-Credentials'] = 'true'
  options.headers['Access-Control-Allow-Headers'] = 'Content-Type'
  options.headers['Access-Control-Allow-Methods'] = 'GET,POST,PATCH,DELETE,PUT,OPTIONS'
  options.headers['key'] = 'x-api-key'
  
  const response = await fetch(endpoint, options)
  if (response.status == 401) {
    console.log('Error: Invalid token')
    await getAuth(fbConf).signOut()
    localStorage.removeItem('token')
  }
  return response
}