import { useEffect } from "react"
import { useNavigate } from "react-router-dom"


export default function Dashboard() {

  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/chargers')
    }, 2000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <></>
  )
}