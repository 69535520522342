export default function dateToTimeAgoString(date: Date) {
  const distanceInMilliseconds = new Date().getTime() - date.getTime()

  const numberEnding = (number: number) => (number > 1) ? 's' : ''

  let temp = Math.floor(distanceInMilliseconds / 1000)

  const years = Math.floor(temp / 31536000)
  if (years) { return `for ${years} year${numberEnding(years)}` }

  const months = Math.floor((temp %= 31536000) / 86400 / 30)
  if (months) { return `for ${months} month${numberEnding(months)}` }

  const weeks = Math.floor((temp %= 31536000) / 86400 / 7)
  if (weeks) { return `for ${weeks} week${numberEnding(weeks)}` }

  const days = Math.floor((temp %= 31536000) / 86400)
  if (days) { return `for ${days} day${numberEnding(days)}` }

  const hours = Math.floor((temp %= 86400) / 3600)
  if (hours) { return `for ${hours} hour${numberEnding(hours)}` }

  const minutes = Math.floor((temp %= 3600) / 60)
  if (minutes) { return `for ${minutes} minute${numberEnding(minutes)}` }

  const seconds = temp % 60
  if (seconds) { return `for ${seconds} second${numberEnding(seconds)}` }

  return 'just now'
}