import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"

export default function Statistics() {
  const { t } = useTranslation()
  
  return (
    <>
      <WhiteSpace height={'18px'} />
      <PageTitle label={t('charger.statistics')} />
      <WhiteSpace height={'12px'} />
    </>
  )
}