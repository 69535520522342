import WhiteSpace from "../../components/Whitespace"
import ChargerLogs from "../Logs/ChargerLogs"

export default function Logs() {
  
  return (
    <>
      <WhiteSpace height={'12px'} />
      <ChargerLogs />
    </>
  )
}