import { useState } from "react"
import ButtonWithIcon from "../components/ButtonWithIcon"
import Modal from "../components/Modal"
import styled from "styled-components"
import WhiteSpace from "../components/Whitespace"
import { useTranslation } from "react-i18next"
import Icon from "../Icon"

const SmallModalContainer = styled.div`
  padding: 24px;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const ExportModalLabel = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: ${props => props.theme.colors.gray_900};
`

const ExportModalSubLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
`

const ExportModalFormatLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
`

export default function ExportModal({ onExport, title }: { onExport: (formats: string[]) => void, title: string }) {
  const { t } = useTranslation()
  
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false)
  const [exportSelectedFormats, setExportSelectedFormats] = useState<string[]>([])

  function handleToggleExportFormats(format: string) {
    const newExportSelectedFormats = [...exportSelectedFormats]
    if (newExportSelectedFormats.includes(format)) {
      newExportSelectedFormats.splice(newExportSelectedFormats.indexOf(format), 1)
    } else {
      newExportSelectedFormats.push(format)
    }
    setExportSelectedFormats(newExportSelectedFormats)
  }

  function handleExport() {
    onExport(exportSelectedFormats)
    setIsExportModalOpen(false)
  }

  return (
    <>
      <ButtonWithIcon icon="general.export" label={t('chargerLogs.export')} onClick={() => setIsExportModalOpen(true)} backgroundColor="white" />
      {isExportModalOpen && (
        <Modal>
          <SmallModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsExportModalOpen(false)} />
            </ModalCloseButtonContainer>
            <ExportModalLabel>{title}</ExportModalLabel>
            <ExportModalSubLabel>*{t('chargerLogs.youCanChooseMultipleFileTypes')}</ExportModalSubLabel>
            <WhiteSpace height="8px" />
            {['.TXT', '.CSV', '.JSON', '.XLS'].map(format => (
              <div key={format} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleToggleExportFormats(format)}>
                <Icon path={exportSelectedFormats.includes(format) ? "general.checkboxFull" : "general.checkboxEmpty"} width="16px" height="16px" marginTop="14px" marginRight="8px" />
                <ExportModalFormatLabel><b>{format}</b> {t('chargerLogs.fileExport')}</ExportModalFormatLabel>
              </div>
            ))}
            <WhiteSpace height="16px" />
            <div style={{ width: "40%" }}>
              <ButtonWithIcon icon="general.export" iconColor="white" label={t('chargerLogs.export')} onClick={handleExport} />
            </div>
          </SmallModalContainer>
        </Modal>
      )}
    </>
  )
}