import { Link } from "react-router-dom";

export default function Navigator({ children, to, newTab }: { children: React.ReactNode, to: string, newTab?: boolean }) {
  return <>
    {to && to != '' ? (
      <Link to={to} target={newTab ? '_blank' : ''} style={{ textDecoration: "inherit", color: "inherit" }}>
        {children}
      </Link>
    ) : (
      <>
        { children }
      </>
    )}
  </>
}