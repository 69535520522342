import styled from "styled-components"
import Icon from "../Icon"

const Container = styled.div<{ $width: string }>`
  width: ${props => props.$width};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.gray_300};
  padding: 12px;
  display: flex;
  position: relative;
`

const Label = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin: 0px;
  margin-top: 4px;
  margin-left: 12px;
`

const Value = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin: 0px;
  margin-top: 16px;
  margin-left: 12px;
`

const DeleteButtonIconContainer = styled.img<{ $theme: string }>`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: 16px;
  cursor: pointer;
`

export default function ListItemWithDeleteButton({ theme, width, icon, label, value, handleDelete }: { theme: string, width: string, icon: string, label: string, value: string, handleDelete: () => void }) {
  return (
    <>
      <Container $width={width}>
        <Icon path={icon} width="24px" height="24px" />
        <div>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </div>
        <DeleteButtonIconContainer $theme={theme} onClick={handleDelete}>
          <Icon path="general.x" width="100%" height="100%" />
        </DeleteButtonIconContainer>
      </Container>
    </>
  )
}