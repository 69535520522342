import styled from "styled-components"

const Contaier = styled.div<{ $toggled: boolean }>`
  width: 50px;
  height: 25px;
  background-color: ${props => props.$toggled ? props.theme.colors.primary : props.theme.colors.gray_300};
  border-radius: 25px;
  padding: 3px;
  cursor: pointer;
  transition: background-color .2s ease-in-out;
`

const Slider = styled.div<{ $toggled: boolean }>`
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 3px 8px 0px #0000001A;
  margin-left: ${props => props.$toggled ? '25px' : '0px'};
  transition: margin-left .2s ease-in-out;
`

export default function Switch({ toggled, setToggled }: {toggled: boolean, setToggled: (value: boolean) => void}) {
  return (
    <Contaier $toggled={toggled} onClick={() => setToggled(!toggled)}>
      <Slider $toggled={toggled} />
    </Contaier>
  )
}