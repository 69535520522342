import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"
import styled from "styled-components"
import { useEffect, useState } from "react"
import Comm from "../../Comm"
import ChargerType from "../../types/ChargerType"
import Dropdown from "../../components/Dropdown"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import Table from "../../modules/Table"
import { TableCellTypes } from "../../enums/TableCellTypes"
import Modal from "../../components/Modal"
import Icon from "../../Icon"
import Button from "../../components/Button"
import InputWithLabel from "../../components/InputWithLabel"
import Switch from "../../components/Switch"
import Select from "../../components/Select"
import { useOutletContext } from "react-router-dom"

interface UserType {
  access_level: number,
  email: string,
  expiry_date: number,
  name: string,
  user_id: string
}

interface RfidType {
  email: string,
  enabled: boolean,
  id: string,
  name: string,
  last_use: number
}

const ToolbarContaier = styled.div`
  position: absolute;
  z-index: 10;
  right: 26px;
  display: flex;
`

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 4px;
  text-align: center;
`

const ModalContainer = styled.div`
  padding-left: 88px;
  padding-right: 88px;
  padding-top: 65px;
  padding-bottom: 65px;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const SwitchLabel = styled.p`
  font-size: 12px;
  font-weight: 700;
  margin-top: 8px;
  color: ${props => props.theme.colors.gray_900};
`

const EmptyTableLabel = styled.p`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.gray_900};
  margin-top: 48px;
  position: absolute;
`

export default function UsersRfid({ charger }: { charger: ChargerType }) {
  const { t } = useTranslation()
  const [theme] = useOutletContext<string>()

  const [users, setUsers] = useState<UserType[] | null>()
  const [rfids, setRfids] = useState<RfidType[] | null>()
  const [lastUsersRefresh, setLastUsersRefresh] = useState<string>('')
  const [lastRfidsRefresh, setLastRfidsRefresh] = useState<string>('')

  //const [isEditingUser, setIsEditingUser] = useState<UserType | null>()

  const [isEditingRfid, setIsEditingRfid] = useState<boolean>(false)
  const [isAddingRfid, setIsAddingRfid] = useState<boolean>(false)
  const [rfidIdInput, setRfidIdInput] = useState<string>()
  const [rfidNameInput, setRfidNameInput] = useState<string>()
  const [rfidEnabledInput, setRfidEnabledInput] = useState<boolean>()
  const [rfidEmailInput, setRfidEmailInput] = useState<string>()


  useEffect(() => {
    async function fetchUsersAndRfids() {
      await fetchUsers()
      await fetchRfids()
    }
    fetchUsersAndRfids()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function fetchUsers() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 46 })
    })
    const result = await response.json()
    setUsers(result.users)
    setLastUsersRefresh(new Date().toString().split(' ')[4])
  }

  async function fetchRfids() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 23 })
    })
    const result = await response.json()
    setRfids(result.rfid_list)
    setLastRfidsRefresh(new Date().toString().split(' ')[4])
  }

  function handleRefreshUsers() {
    setUsers(null)
    fetchUsers()
  }

  function handleRefreshRfids() {
    setRfids(null)
    fetchRfids()
  }

  function startEditRfid(rfid: RfidType) {
    setRfidIdInput(rfid.id)
    setRfidNameInput(rfid.name)
    setRfidEmailInput(rfid.email)
    setRfidEnabledInput(rfid.enabled)
    setIsEditingRfid(true)
  }

  async function saveEditRfid() {
    console.log(rfidEmailInput)
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 25, "rfid_tag": { "id": rfidIdInput, "name": rfidNameInput, "enabled": rfidEnabledInput, "email": rfidEmailInput } })
    })
    setRfidIdInput('')
    setRfidNameInput('')
    setRfidEmailInput('')
    setRfidEnabledInput(true)
    setIsEditingRfid(false)
    handleRefreshRfids()
  }

  function startAddRfid() {
    setRfidIdInput('')
    setRfidNameInput('')
    setRfidEmailInput('')
    setRfidEnabledInput(true)
    setIsAddingRfid(true)
  }

  async function saveAddRfid() {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 54, "rfid_tag": { "id": rfidIdInput, "name": rfidNameInput, "enabled": rfidEnabledInput } })
    })
    setRfidIdInput('')
    setRfidNameInput('')
    setRfidEmailInput('')
    setRfidEnabledInput(true)
    setIsAddingRfid(false)
    handleRefreshRfids()
  }

  async function deleteRfid(rfid: RfidType) {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 26, "id": rfid.id })
    })
    handleRefreshRfids()
  }

  return (
    <>
      {isEditingRfid && <>
        <Modal>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsEditingRfid(false)} />
            </ModalCloseButtonContainer>
            <WhiteSpace height="20px" />
            <InputWithLabel label={t('charger.usersrfids.rfidId')} width={"16vw"} value={rfidIdInput || ''} onInput={() => { }} />
            <WhiteSpace height="8px" />
            <InputWithLabel label={t('charger.usersrfids.rfidName')} width={"16vw"} value={rfidNameInput || ''} onInput={(e) => setRfidNameInput(e)} />
            <WhiteSpace height="8px" />
            <Select theme={theme} width={"calc(100% - 24px)"} options={users ? [rfidEmailInput || '', ...users.map((user) => user.email)] : []} handleSelect={(e) => setRfidEmailInput(e)} isAbsolute />
            <WhiteSpace height="12px" />
            <div style={{ display: 'flex' }}>
              <SwitchLabel>{`${t('charger.enabled')}:`}</SwitchLabel>
              <div style={{ position: 'absolute', right: '88px' }}>
                <Switch toggled={rfidEnabledInput || false} setToggled={setRfidEnabledInput} />
              </div>
            </div>
            <WhiteSpace height="12px" />
            <div style={{ display: 'flex' }}>
              <Button onclick={() => setIsEditingRfid(false)} label={t('charger.cancel')} width='calc(8vw - 8px)' bgcolor="transparent" labelcolor="black" />
              <WhiteSpace width="8px" />
              <Button onclick={saveEditRfid} label={t('charger.save')} width='8vw' />
            </div>
          </ModalContainer>
        </Modal>
      </>}

      {isAddingRfid && <>
        <Modal>
          <ModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsEditingRfid(false)} />
            </ModalCloseButtonContainer>
            <WhiteSpace height="20px" />
            <InputWithLabel label={t('charger.usersrfids.rfidId')} width={"16vw"} value={rfidIdInput || ''} onInput={(e) => setRfidIdInput(e)} />
            <WhiteSpace height="8px" />
            <InputWithLabel label={t('charger.usersrfids.rfidName')} width={"16vw"} value={rfidNameInput || ''} onInput={(e) => setRfidNameInput(e)} />
            <WhiteSpace height="12px" />
            <div style={{ display: 'flex' }}>
              <SwitchLabel>Enabled:</SwitchLabel>
              <div style={{ position: 'absolute', right: '88px' }}>
                <Switch toggled={rfidEnabledInput || false} setToggled={setRfidEnabledInput} />
              </div>
            </div>
            <WhiteSpace height="12px" />
            <div style={{ display: 'flex' }}>
              <Button onclick={() => setIsAddingRfid(false)} label={t('charger.cancel')} width='calc(8vw - 8px)' bgcolor="transparent" labelcolor="black" />
              <WhiteSpace width="8px" />
              <Button onclick={saveAddRfid} label={t('charger.save')} width='8vw' />
            </div>
          </ModalContainer>
        </Modal>
      </>}


      <WhiteSpace height={'12px'} />
      <PageTitle label={t('charger.users')} />
      <ToolbarContaier>
        <div>
          <ButtonWithIcon icon="general.refresh" label={t('logs.refresh')} onClick={handleRefreshUsers} backgroundColor="success" iconColor="white" />
          <RefreshTimerLabel>{lastUsersRefresh}</RefreshTimerLabel>
        </div>
      </ToolbarContaier>
      {users && users.length > 0 ? <>
        <Table id="UsersRfids_Users" height="calc((90vh - 345px) / 2)" isSearchEnabled={true} isPaginationEnabled={true} columns={[
          { width: '30px' },
          { label: t('charger.userEmail'), width: '20%' },
          { label: t('charger.nameUserUid'), width: '20%' },
          { label: t('charger.permissionLevel'), width: '20%' },
          { label: t('charger.created'), width: '20%' },
          { label: t('charger.signedIn') },
          { width: '20px' }
        ]} data={users.map(user => [
          {
            value: {
              type: TableCellTypes.Image,
              src: 'chargers.user'
            }
          }, {
            sortBy: user.email,
            value: {
              type: TableCellTypes.Label,
              value: user.email || '-'
            }
          }, {
            sortBy: user.name,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: user.name,
              value2: user.user_id
            }
          }, {
            sortBy: user.access_level,
            value: {
              type: TableCellTypes.Status,
              label: t(`charger.userAccessLevel.${user.access_level}`),
              themeColor: (() => {
                switch (user.access_level) {
                  case 0: return 'gray_400'
                  case 1: return 'gray_400'
                  case 2: return 'primary'
                  case 3: return 'gray_900'
                  case 4: return 'gray_400'
                  case 5: return 'gray_400'
                  case 6: return 'gray_400'
                  case 7: return 'success'
                  default: return 'success'
                }
              })()
            }
          }, {
            value: {
              type: TableCellTypes.Label,
              value: '-'
            }
          }, {
            value: {
              type: TableCellTypes.Label,
              value: '-'
            }
          }, {
            custom: <Dropdown handleChoose={(value) => console.log(value, user)} title={{ icon: "general.more", label: '' }} options={[{ icon: "general.trash", label: t('charger.delete'), color: '#FD4E4E' }]} showOptionContainerOnLeft />
          }
        ])} />
      </> : <>
        <EmptyTableLabel>{t(`charger.noUsers`)}</EmptyTableLabel>
        <WhiteSpace height="calc((90vh - 124px) / 2)" />
      </>}






      <PageTitle label={t('charger.rfidManagement')} />
      <ToolbarContaier>
        <div>
          <ButtonWithIcon icon="general.plus" label={t('charger.usersrfids.addNewRfid')} onClick={startAddRfid} iconColor="white" backgroundColor="dark" />
        </div>
        <WhiteSpace width="8px" />
        <div>
          <ButtonWithIcon icon="general.refresh" label={t('logs.refresh')} onClick={handleRefreshRfids} backgroundColor="success" iconColor="white" />
          <RefreshTimerLabel>{lastRfidsRefresh}</RefreshTimerLabel>
        </div>
      </ToolbarContaier>
      {rfids && rfids.length > 0 ? <>
        <Table id="UsersRfids_Rfids" height="calc((90vh - 345px) / 2)" isSearchEnabled={true} isPaginationEnabled={true} columns={[
          { width: '30px' },
          { label: t('charger.rfidName'), width: '20%' },
          { label: t('charger.rfidState'), width: '20%' },
          { label: t('charger.rfidNumber'), width: '20%' },
          { label: t('charger.lastUse'), width: '20%' },
          { label: t('charger.user') },
          { width: '20px' }
        ]} data={rfids.map(rfid => [
          {
            value: {
              type: TableCellTypes.Image,
              src: 'chargers.rfidTag'
            }
          }, {
            sortBy: rfid.name,
            value: {
              type: TableCellTypes.Label,
              value: rfid.name || '-'
            }
          }, {
            sortBy: rfid.enabled ? 'a' : 'b',
            value: {
              type: TableCellTypes.Status,
              label: rfid.enabled ? t('charger.active') : t('charger.inactive'),
              themeColor: rfid.enabled ? 'success' : 'gray_400'
            }
          }, {
            sortBy: rfid.id,
            value: {
              type: TableCellTypes.Label,
              value: rfid.id || '-'
            }
          }, {
            sortBy: rfid.last_use || 0,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: rfid.last_use ? new Date(rfid.last_use * 1000).toISOString().split('T')[0].replace(/-/g, '.') : '-',
              value2: rfid.last_use ? new Date(rfid.last_use * 1000).toISOString().split('T')[1].split('.')[0] : ''
            }
          }, {
            sortBy: rfid.email || '',
            value: {
              type: TableCellTypes.Label,
              value: rfid.email || '-'
            }
          }, {
            custom: <Dropdown handleChoose={(value) => value == t('charger.edit') ? startEditRfid(rfid) : deleteRfid(rfid)} title={{ icon: "general.more", label: '' }} options={[{ icon: 'general.wrench', label: t('charger.edit') }, { icon: "general.trash", label: t('charger.delete'), color: '#FD4E4E' }]} showOptionContainerOnLeft />
          }
        ])} />
      </> : <EmptyTableLabel>{t(`charger.noRfids`)}</EmptyTableLabel>}
    </>
  )
}