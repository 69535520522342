import { useState, useRef } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';

const Container = styled.div<{ $width: string }>`
  width: ${props => props.$width};
  position: relative;
`

const Track = styled.div<{ $bgColorLeft: string, $bgColorRight: string }>`
  width: 100%;
  height: 32px;
  border-radius: 40px;
  position: relative;
  background: ${props => `linear-gradient(90deg, ${props.$bgColorLeft} 0%, ${props.$bgColorRight} 100%)`};
`

const Thumb = styled.div<{ $thumbPosition: number, $isSliding: boolean }>`
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 28px;
  background-color: ${props => props.theme.colors.white};
  position: absolute;
  left: ${(props) => props.$thumbPosition}px;
  cursor: pointer;
  transition: ${(props) => (props.$isSliding ? 'none' : 'left 0.3s ease')};
`

const IconContainer = styled.div`
  width: 22px;
  height: 22px;
  margin-left: 3px;
  margin-top: 3px;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
`

const ConfirmText = styled.span`
  position: absolute;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
  font-weight: 700;
  font-size: 13px;
  margin-top: 7px;
  color: ${props => props.theme.colors.white};
`

export default function SlideToConfirm({ onConfirm, width, label, icon, bgColorLeft, bgColorRight }: { onConfirm: () => void, width: string, label: string, icon: string, bgColorLeft: string, bgColorRight: string }) {
  const [thumbPosition, setThumbPosition] = useState(0)
  const [isSliding, setIsSliding] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const containerRef = useRef(null);

  const handleMouseDown = () => {
    setIsSliding(true)
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isSliding && !isConfirmed) {
      //@ts-expect-error This event can only occur if the container is rendered, the ref exists for sure
      const containerRect = containerRef.current.getBoundingClientRect()
      const newThumbPosition = Math.min(Math.max(e.clientX - containerRect.left - 14, 0), containerRect.width - 32)
      setThumbPosition(newThumbPosition)
    }
  }

  const handleMouseUp = () => {
    if (isSliding) {
      setIsSliding(false)
      //@ts-expect-error This event can only occur if the container is rendered, the ref exists for sure
      if (thumbPosition >= containerRef.current.getBoundingClientRect().width - 32) {
        setIsConfirmed(true)
        onConfirm()
      } else {
        setThumbPosition(0)
      }
    }
  }

  return (
    <Container $width={width} ref={containerRef} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
      <Track $bgColorLeft={bgColorLeft} $bgColorRight={bgColorRight}>
        <ConfirmText>{label}</ConfirmText>
        <Thumb $thumbPosition={thumbPosition} $isSliding={isSliding} onMouseDown={handleMouseDown}>
          <IconContainer>
            <Icon path={icon} width="100%" height="100%" />
          </IconContainer>
        </Thumb>
      </Track>
    </Container>
  )
}