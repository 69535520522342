import { createGlobalStyle } from 'styled-components';
import Inter from './assets/Inter.ttf'

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Inter';
    src: url(${Inter});
  }
  
  html {
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
  }

  body {
    margin: 0px;
    background-color: ${props => props.theme.colors.background};
    font-family: Inter;
    overflow: hidden;
    scrollbar-gutter: stable;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; /* For Firefox on macOS */
    font-synthesis: none !important;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.gray_400};
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.gray_600}; 
  }
`

export default GlobalStyle