import styled from "styled-components"
import Icon from "../Icon"

const Container = styled.div`
  position: relative;
`

const InputElement = styled.input<{ $width: string }>`
  background: none;
  border: 1px solid ${props => props.theme.colors.gray_300};
  border-radius: 8px;
  width: calc(${props => props.$width} - 32px);
  outline: none;
  padding: 12px;
  padding-left: 40px;
  background-color: ${props => props.theme.colors.white};
`

export default function InputWithIcon({ width, placeholder, icon, iconColor, onInput }: { width: string, placeholder: string, icon: string, iconColor?: string, onInput: (value: string) => void }) {
  return (
    <Container>
      <InputElement onInput={(e) => onInput((e.target as HTMLInputElement).value)} $width={width} placeholder={placeholder} />
      <div style={{ position: "absolute", top: "12px", left: "12px" }}>
        <Icon path={icon} color={iconColor} width="16px" height="16px" />
      </div>
    </Container>
  )
}