import styled from "styled-components";

const Label = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${props => props.theme.colors.gray_900};
  margin-left: 19px;
`

export default function PageTitle({ label }: { label: string }) {
  return (
    <Label>{label}</Label>
  )
}