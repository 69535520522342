import styled from "styled-components"

const ButtonElement = styled.button<{ $width: string, $bgcolor?: string, $labelcolor?: string }>`
  border: none;
  background: none;
  background-color: ${props => props.$bgcolor || props.theme.colors.dark};
  color: ${props => props.$labelcolor || props.theme.colors.white};
  font-weight: 600;
  border-radius: 12px;
  width: ${props => props.$width};
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: pointer;

  &:active {
    transform: scale(.99);
  }
`

export default function Button({ label, width, onclick, bgcolor, labelcolor }: { label: string, width: string, onclick: () => void, bgcolor?: string, labelcolor?: string }) {
  return (
    <ButtonElement onClick={onclick} value={label} $width={width} $bgcolor={bgcolor} $labelcolor={labelcolor}>{label}</ButtonElement>
  )
}