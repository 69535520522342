import { useEffect, useRef, useState } from "react"
// @ts-expect-error react-rainbow-components does not have a TS export, but its fine
import { DatePicker } from 'react-rainbow-components'
import styled from "styled-components"
import Icon from "../Icon"

const Container = styled.div`
  display: flex;
  cursor: pointer;
`

const Label = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 12px;
  margin-left: 8px;
`

export default function DateRangePicker({ onSelect, defaultDate }: { theme: string, onSelect: (value: Date[]) => void, defaultDate?: Date[] }) {
  const datepickerRef = useRef(null)

  const [pickedRange, setPickedRange] = useState<{range: Date[]}>({ range: defaultDate ? defaultDate : [new Date()] })
  const [displayDate, setDisplayDate] = useState<Date[]>(defaultDate ? defaultDate : [new Date()])

  function handleChange(value: { range: Date[] }) {
    setPickedRange(value)

    const newValue: { range: Date[] } = { range: [] }
    if (value.range.length == 1) {
      newValue.range = [new Date(value.range[0].getTime())]
    } else {
      newValue.range = [new Date(value.range[0].getTime()), new Date(value.range[1].getTime())]
    }
    newValue.range[0].setDate(newValue.range[0].getDate() + 1)

    onSelect(newValue.range)
    setDisplayDate(newValue.range)
  }

  // Display the date if changed from outside (dafaultDate is a stupid name for this)
  useEffect(() => {
    if (defaultDate) {
      setDisplayDate(defaultDate)
    }
  }, [defaultDate])

  return (
    <>
      <div style={{ display: 'none' }}>

        <DatePicker ref={datepickerRef}
          selectionType="range"
          formatStyle="medium"
          variant="single"
          value={pickedRange.range}
          onChange={(value: Date[]) => handleChange({ range: value })}
        />
      </div>
      {/* @ts-expect-error TS thinks that datepickerRef.current does not have a click method, it does */}
      <Container onClick={() => datepickerRef.current && datepickerRef.current.click()}>
        <Icon path="general.calendar" width="20px" height="20px" marginTop="10px" />
        {!pickedRange.range[1] ? (
          <Label>{displayDate[0].toISOString().split('T')[0].replace(/-/g, '.')}</Label>
        ) : (
          <Label>{`${displayDate[0].toISOString().split('T')[0].replace(/-/g, '.')} - ${displayDate[1].toISOString().split('T')[0].replace(/-/g, '.')}`}</Label>
        )}
      </Container>
    </>
  )
}