import React, { useEffect } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Navigator from "../components/Navigator"
import Icon from "../Icon"

interface MenuCategory {
  name: string,
  options: MenuItem[]
}

interface MenuItem {
  name: string,
  icon: string,
  path: string,
  disabled?: boolean
}

const CategoryLabel = styled.p<{ $isMenuOpen: boolean }>`
  margin-left: ${props => props.$isMenuOpen ? '3vw' : '1.5vw'};
  font-weight: 700;
  font-size: 12px;
  color: #2D3748;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  transition: margin-left .2s ease-in-out;
`

const Option = styled.div<{ $isMenuOpen: boolean, $isSelected: boolean, $isDisbled: boolean }>`
  display: flex;
  width: ${props => props.$isMenuOpen ? '11.5vw' : 'calc(30px + (16px * 2))'};
  margin-left: ${props => props.$isMenuOpen ? '2vw' : '1vw'};
  background-color: ${props => props.$isSelected && '#FFFFFF'};
  border-radius: 15px;
  cursor: ${props => props.$isDisbled ? 'default' : 'pointer'};
  transition: width .2s ease-in-out, margin-left .2s ease-in-out;
  position: relative;
`

const OptionIconContainer = styled.div<{ $isSelected: boolean }>`
  width: 30px;
  height: 30px;
  margin-left: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 12px;

  ${props => props.$isSelected && `
    background-color: ${props.theme.colors.primary};
  `}
`

const OptionLabel = styled.span<{ $isDisbled: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.$isDisbled ? props.theme.colors.gray_500 : props.theme.colors.dark}/* #2D3748*/;
  line-height: calc(30px + (12px * 2));
  margin-left: 16px;
  white-space: nowrap;
`

const SoonBadge = styled.p`
  position: absolute;
  right: 10px;
  font-weight: 600;
  font-size: 10px;
  color: ${props => props.theme.colors.white};
  background: linear-gradient(140.77deg, #F748A6 17.7%, #DC1A48 79.78%);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  margin: 0px;
  margin-top: 18px;
`

export default function Menu({ isMenuOpen, selectedMenuItem, setSelectedMenuItem }: { isMenuOpen: boolean, selectedMenuItem: string, setSelectedMenuItem: (value: string) => void }) {
  const { t } = useTranslation()
  const location = useLocation()

  const menuItems: MenuCategory[] = [
    {
      name: '',
      options: [
        {
          name: 'dashboard',
          icon: 'menu.dashboard',
          path: '/',
          disabled: true
        },
        {
          name: 'chargers',
          icon: 'menu.chargers',
          path: '/chargers'
        },
        {
          name: 'chargerGroups',
          icon: 'menu.chargergroups',
          path: '/chargergroups'
        },
        {
          name: 'logs',
          icon: 'menu.logs',
          path: '/logs'
        }
      ]
    },
    {
      name: 'admin',
      options: [
        {
          name: 'appVersion',
          icon: 'menu.appversion',
          path: '/appversion'
        },
        {
          name: 'users',
          icon: 'menu.users',
          path: '/users',
          disabled: true
        },
        {
          name: 'settings',
          icon: 'menu.settings',
          path: '/settings',
          disabled: true
        },
        {
          name: 'notifications',
          icon: 'menu.notifications',
          path: '/notifications',
          disabled: true
        }
      ]
    }
  ]

  useEffect(() => {
    setCurrentMenuItem()
  }, [location.pathname])

  function setCurrentMenuItem() {
    const pathName = `/${location.pathname.split('/')[1]}`
    for (const menuItem of menuItems) {
      for (const option of menuItem.options) {
        if (option.path == pathName) {
          setSelectedMenuItem(option.name)
        }
      }
    }
  }

  return (
    <>
      {menuItems && menuItems.map((category) => (
        <React.Fragment key={`${category.name}`}>
          {category.name && (
            <CategoryLabel $isMenuOpen={isMenuOpen}>{t(`menu.${category.name}`)}</CategoryLabel>
          )}
          {category.options.map(item => (
            <Navigator to={!item.disabled ? item.path : ''}>
              <Option key={`${category.name}-${item.name}`} onClick={() => !item.disabled && setCurrentMenuItem} $isMenuOpen={isMenuOpen} $isSelected={item.name == selectedMenuItem} $isDisbled={item.disabled || false}>
                <OptionIconContainer $isSelected={item.name == selectedMenuItem}>
                  <Icon path={item.name == selectedMenuItem ? item.icon + "Active" : item.icon} width="16px" height="16px" marginLeft="7px" marginTop="7px" color="none" />
                </OptionIconContainer>
                {isMenuOpen && (
                  <>
                    {item.disabled ? (
                      <>
                        <OptionLabel $isDisbled={item.disabled || false}>{t(`menu.${item.name}`)}</OptionLabel>
                        <SoonBadge>Soon</SoonBadge>
                      </>
                    ) : (
                      <Navigator to={item.path}>
                        <OptionLabel $isDisbled={item.disabled || false}>{t(`menu.${item.name}`)}</OptionLabel>
                      </Navigator>
                    )}
                  </>
                )}
              </Option>
            </Navigator>
          ))}
        </React.Fragment>
      ))}
    </>
  )
}