import styled from "styled-components"
import Icon from "../Icon"

const Container = styled.div<{ $backgroundColor?: string }>`
  background-color: ${props => props.$backgroundColor ? props.theme.colors[props.$backgroundColor] : props.theme.colors.primary};
  border-radius: 6px;
  padding: 6px;
  display: flex;
  cursor: pointer;
  outline: ${props => props.$backgroundColor == 'white' ? `1px solid ${props.theme.colors.gray_300}` : 'none'};

  &:active {
    transform: scale(.98);
  }
`

const Label = styled.p<{ $backgroundColor?: string }>`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.$backgroundColor == 'white' ? props.theme.colors.dark : props.theme.colors.white};
  margin-bottom: 0px;
  margin-top: -1px;
  margin-left: 6px;
  margin-right: 6px;
`

export default function ButtonWithIcon({ icon, label, onClick, backgroundColor, iconColor }: { icon?: string, label: string, onClick: () => void, backgroundColor?: string, iconColor?: string }) {
  return (
    <Container onClick={onClick} $backgroundColor={backgroundColor}>
      {icon && (
        <div style={{ marginLeft: "6px", marginTop: "-1px", height: "18px" }}>
          <Icon path={icon} color={iconColor} width="14px" height="14px" />
        </div>
      )}
      <Label $backgroundColor={backgroundColor}>{label}</Label>
    </Container>
  )
}