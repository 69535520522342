import { useTranslation } from "react-i18next"
import PageTitle from "../../components/PageTitle"
import styled from "styled-components"
import { useEffect, useState } from "react"
import Comm from "../../Comm"
import ChargerType from "../../types/ChargerType"
import Table from "../../modules/Table"
import { TableCellTypes } from "../../enums/TableCellTypes"
import dateToTimeAgoString from "../../utils/dateToTimeAgoString"
import ButtonWithIcon from "../../components/ButtonWithIcon"

const ProgressbarOutside = styled.div`
  width: 80px;
  height: 5px;
  background-color: ${props => props.theme.colors.primaryLight};
  border-radius: 5px;
`

const ProgressbarInside = styled.div<{ $progressPercent: number, $color: string }>`
  width: ${props => `${props.$progressPercent}%`};
  height: 5px;
  background-color: ${props => props.$color};
  border-radius: 5px;
`

const ProgressLabel = styled.p`
  font-weight: 600;
  font-size: 11px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: -5px;
  margin-left: 12px;
`

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 4px;
  text-align: center;
`

const ToolbarContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 26px;
  display: flex;
`

export default function Logs() {
  const { t } = useTranslation()

  const [chargers, setChargers] = useState<ChargerType[]>()
  const [lastRefresh, setLastRefresh] = useState<string>('')

  async function fetchChargers() {
    const response = await Comm('/api-charger/chargers', {})
    const result = await response.json()
    setLastRefresh(new Date().toString().split(' ')[4])

    // Get available log days for all chargers
    const allLogDaysResponse = await Comm('/api-log/charger/log/frontend/available', {})
    const allLogDaysResult = await allLogDaysResponse.json()
    const allLogDaysObject: { [key: string]: string[] } = {}
    allLogDaysResult.map((charger: { chargerId: string, availableDays: string[] }) => allLogDaysObject[charger.chargerId] = charger.availableDays)

    const allChargersWithAvailableDays: ChargerType[] = []
    for (const charger of result) {
      allChargersWithAvailableDays.push({ ...charger, availableLogDays: allLogDaysObject[charger.chargerId] || [] })
    }
    setChargers(allChargersWithAvailableDays)
  }
  useEffect(() => {
    fetchChargers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handelRefresh() {
    setChargers([])
    fetchChargers()
  }

  return (
    <>
      <PageTitle label={t('logs.LOGs')} />
      <ToolbarContainer>
        <div>
          <ButtonWithIcon icon="general.refresh" label={t('chargers.refresh')} onClick={handelRefresh} backgroundColor="success" iconColor="white" />
          <RefreshTimerLabel>{lastRefresh}</RefreshTimerLabel>
        </div>
      </ToolbarContainer>
      {chargers && chargers.length > 0 && (
        <Table id="Logs" height="calc(90vh - 170px)" isSearchEnabled={true} isPaginationEnabled={true} columns={[
          { width: '30px' },
          { label: t('logs.chargerIdUserEmail'), width: '20%' },
          { label: t('logs.status'), width: '14%' },
          { label: t('logs.lastLog') },
          { label: t('logs.normalPercentage'), width: '15%' },
          { label: t('logs.warningPercentage'), width: '15%' },
          { label: t('logs.errorPercentage'), width: '15%' },
          { width: '20px' },
        ]} data={chargers.map(charger => [
          {
            link: `/logs/charger/${charger.chargerId}`,
            value: {
              type: TableCellTypes.Image,
              src: "general.charger"
            }
          }, {
            link: `/logs/charger/${charger.chargerId}`,
            sortBy: charger.chargerId,
            value: {
              type: TableCellTypes.LabelDivided,
              value1: charger.chargerId,
              value2: charger.userEmail
            }
          }, {
            link: `/logs/charger/${charger.chargerId}`,
            sortBy: charger.online ? '1' : '0',
            value: {
              type: TableCellTypes.Status,
              label: charger.online ? 'Online' : 'Offline',
              themeColor: charger.online ? 'success' : 'gray_400',
              labelBelow: charger.online ? null : dateToTimeAgoString(new Date(charger.lastPresence.replace(/\./g, '-')))
            }
          }, {
            link: `/logs/charger/${charger.chargerId}`,
            sortBy: charger.availableLogDays.length > 0 ? new Date(charger.availableLogDays[0]).getTime() : 0,
            value: {
              type: TableCellTypes.Label,
              value: charger.availableLogDays.length > 0 ? charger.availableLogDays[0].replace(/-/g, '.') : '-'
            }
          }, {
            link: `/logs/charger/${charger.chargerId}`,
            custom: <>
              <div style={{ display: 'flex' }}>
                <ProgressbarOutside>
                  <ProgressbarInside $progressPercent={50} $color="#4AB1FC" />
                </ProgressbarOutside>
                <ProgressLabel>{'50%'}</ProgressLabel>
              </div>
            </>
          }, {
            link: `/logs/charger/${charger.chargerId}`,
            custom: <>
              <div style={{ display: 'flex' }}>
                <ProgressbarOutside>
                  <ProgressbarInside $progressPercent={50} $color="#FDD85D" />
                </ProgressbarOutside>
                <ProgressLabel>{'50%'}</ProgressLabel>
              </div>
            </>
          }, {
            link: `/logs/charger/${charger.chargerId}`,
            custom: <>
              <div style={{ display: 'flex' }}>
                <ProgressbarOutside>
                  <ProgressbarInside $progressPercent={50} $color="#FD4E4E" />
                </ProgressbarOutside>
                <ProgressLabel>{'50%'}</ProgressLabel>
              </div>
            </>
          }, {
            link: `/logs/charger/${charger.chargerId}`,
            value: {
              type: TableCellTypes.OpenButton
            }
          }
        ])} />
      )}
    </>
  )
}