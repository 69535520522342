import styled from "styled-components"

const Check = styled.input<{ $isChecked: boolean }>`
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.$isChecked ? props.theme.colors.primary : 'transparent'};
  width: 17px;
  height: 17px;
  border-radius: 4.7px;
  transform: translateY(0.1em);
  float: left;
  margin-right: 8px;
  border: ${props => props.$isChecked ? 'none' : `1px solid ${props.theme.colors.gray_500}`};

  display: grid;
  place-content: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 0.55em;
    height: 0.55em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: ${props => props.theme.colors.white};
  }

  &:checked::before {
    transform: scale(1);
  }
`

const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_700};
`

export default function Checkbox({ label, value, onClick }: { label: string, value: boolean, onClick: () => void }) {
  return (
    <div>
      <Check type="checkbox" checked={value} $isChecked={value} onChange={onClick} />
      <Label>{label}</Label>
    </div>
  )
}