import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ChargerType from "../../types/ChargerType"
import Comm from "../../Comm"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import GeneralInformation from "./GeneralInformation"
import Charges from "./Charges"
import Statistics from "./Statistics"
import UsersRfid from "./UsersRfid"
import Logs from "./Logs"
import Settings from "./Settings"
import PushNotifications from "./PushNotifications"
import ExtLogUpload from "./ExtLogUpload"
import Icon from "../../Icon"

interface MenuElementType {
  id: string,
  isOfflineSafe: boolean
}

const MenuContainer = styled.div`
  position: absolute;
  top: -1.5vh;
  display: flex;
`

const MenuElement = styled.p<{ $selected: boolean, $isDisabled: boolean }>`
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.$selected ? props.theme.colors.gray_900 : props.$isDisabled ? props.theme.colors.gray_400 : props.theme.colors.gray_700};
  margin: 0px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 2px;
  border-bottom: ${props => props.$selected ? `2px solid ${props.theme.colors.primary}` : '0px solid black'};
  cursor: ${props => props.$isDisabled ? 'default' : 'pointer'};
`

const TempChargerIdLabel = styled.p`
  position: absolute;
  top: -7vh;
  left: 1vw;
  font-size: 14px;
  font-weight: 700;
`

export default function Charger() {
  const { t } = useTranslation()
  const { chargerId, subpath } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  //const menuElements: string[] = ['generalInformation', 'charges', 'statistics', 'usersRfid', 'logs', 'settings', 'pushNotifications', 'extLogUpload']

  const menuElements: MenuElementType[] = [
    {
      id: 'generalInformation',
      isOfflineSafe: true
    }, {
      id: 'charges',
      isOfflineSafe: true
    }, {
      id: 'statistics',
      isOfflineSafe: true,
    }, {
      id: 'usersRfid',
      isOfflineSafe: false
    }, {
      id: 'logs',
      isOfflineSafe: true
    }, {
      id: 'settings',
      isOfflineSafe: false
    }, {
      id: 'pushNotifications',
      isOfflineSafe: true
    }, {
      id: 'extLogUpload',
      isOfflineSafe: false
    }
  ]

  const [charger, setCharger] = useState<ChargerType>()
  const [page, setPage] = useState<string>(menuElements[0].id)

  async function fetchCharger() {
    const response = await Comm('/api-charger/charger', {
      headers: { "Charger_id": chargerId, "Content-Type": "application/json" }
    })
    const result = await response.json()

    // Ping charger to determine if actually online
    const isOnline = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: { "Charger_id": chargerId, "Content-Type": "application/json" },
      body: JSON.stringify({ "msg_type": 11 })
    })
    if (isOnline.status == 200) {
      result.online = true
    } else {
      result.online = false
    }

    setCharger(result)
  }
  useEffect(() => {
    fetchCharger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (subpath) {
      setPage(subpath)
    } else {
      setPage('generalInformation')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function navigateToSubpage(menuElement: string) {
    setPage(menuElement)
    if (menuElement == 'generalInformation') {
      navigate(`/chargers/${chargerId}`)
    } else {
      navigate(`/chargers/${chargerId}/${menuElement}`)
    }
  }


  return (
    <>
      <MenuContainer>
        <Icon path="general.backArrow" width="16px" height="16px" marginTop="3px" onClick={() => navigate('/chargers')} />
        <table>
          <tbody>
            <tr>
              {menuElements.map(menuElement => <>
                <td key={menuElement.id}>
                  <MenuElement $isDisabled={charger ? (!charger.online && !menuElement.isOfflineSafe) : true} $selected={page == menuElement.id} onClick={() => !(charger ? (!charger.online && !menuElement.isOfflineSafe) : true) && navigateToSubpage(menuElement.id)}>{t(`charger.${menuElement.id}`)}</MenuElement>
                </td>
              </>)}
            </tr>
          </tbody>
        </table>
      </MenuContainer>
      {charger && <>
        <TempChargerIdLabel>{`${chargerId} - ${charger.localName}`}</TempChargerIdLabel>
      </>}
      {charger && (
        <>
          {page == 'generalInformation' && <GeneralInformation charger={charger} />}
          {page == 'charges' && <Charges charger={charger} />}
          {page == 'statistics' && <Statistics />}
          {page == 'usersRfid' && <UsersRfid charger={charger} />}
          {page == 'logs' && <Logs />}
          {page == 'settings' && <Settings charger={charger} />}
          {page == 'pushNotifications' && <PushNotifications charger={charger} />}
          {page == 'extLogUpload' && <ExtLogUpload charger={charger} />}
        </>
      )}
    </>
  )
}