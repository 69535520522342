import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"
import styled from "styled-components";
import { useEffect, useState } from "react";
import Comm from "../../Comm";
import InputWithIcon from "../../components/InputWithIcon";
import ChargerType from "../../types/ChargerType";
import PushNotificationType from "../../types/PushNotificationType";
import Icon from "../../Icon";

const NotificationsContainer = styled.div`
  width: 100%;
  height: calc(90vh - 170px);
  border-radius: 16px;
  overflow-y: scroll;
`

const NotificationContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 10px;
  width: 100%;
  border-radius: 16px;
  padding: 24px;
`

const NotificationTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-left: 24px;
`

const NotificationBody = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-left: 24px;
  padding-top: 12px;
`

const NotificationTime = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_600};
  margin: 0px;
  margin-left: 24px;
  padding-top: 12px;
`

const ToolbarContaier = styled.div`
  width: 100%;
  display: flex;
`

const RefreshButtonContainer = styled.div`
  background-image: linear-gradient(to right, ${props => props.theme.colors.successActive}, ${props => props.theme.colors.success});
  border-radius: 6px;
  padding: 8px;
  display: flex;
  cursor: pointer;
  float: left;
  margin-left: 24px;

  &:active {
    transform: scale(.98);
  }
`

const RefreshButtonLabel = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.white};
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 6px;
  margin-right: 6px;
`

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 32px;
  margin-left: 24px;
  text-align: center;
`

export default function PushNotifications({ charger }: { charger: ChargerType }) {
  const { t } = useTranslation()

  const [pushNotifications, setPushNotifications] = useState<PushNotificationType[]>()
  const [lastRefresh, setLastRefresh] = useState<string>('')
  const [searchResults, setSearchResults] = useState<PushNotificationType[] | null>(null)

  async function fetchPushNotifications() {
    const response = await Comm('/api-notification/push', {
      headers: { "charger_id": charger.chargerId }
    })
    const result = await response.json()
    console.log(result.pushes)
    setPushNotifications(result.pushes)
    setLastRefresh(new Date().toString().split(' ')[4])
  }
  useEffect(() => {
    fetchPushNotifications()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handelRefresh() {
    setPushNotifications([])
    fetchPushNotifications()
  }

  function handleSearch(input: string) {
    if (pushNotifications) {
      if (input != '') {
        const results: PushNotificationType[] = []
        for (const notification of pushNotifications) {
          const searchString = `${notification.userId} ${notification.chargerId} ${notification.pushTitle} ${notification.pushBody}`
          if (searchString.toLocaleLowerCase().includes(input.toLocaleLowerCase())) {
            results.push(notification)
          }
        }
        if (results.length < 1) {
          setSearchResults([])
        } else {
          setSearchResults(results)
        }
      } else {
        setSearchResults(null)
      }
    }
  }

  return (
    <>
      <WhiteSpace height={'18px'} />
      <PageTitle label={t('charger.pushNotifications')} />
      <WhiteSpace height={'12px'} />
      <ToolbarContaier>
        <div>
          <RefreshButtonContainer onClick={handelRefresh}>
            <div style={{ marginLeft: "6px" }}>
              <Icon path="general.refresh" color="white" width="16px" height="16px" />
            </div>
            <RefreshButtonLabel>{t('logs.refresh')}</RefreshButtonLabel>
          </RefreshButtonContainer>
          <WhiteSpace height={'6px'} />
          <RefreshTimerLabel>{lastRefresh}</RefreshTimerLabel>
        </div>
        <WhiteSpace width={'42px'} />
        <InputWithIcon onInput={(input) => handleSearch(input)} width="12vw" placeholder={t('logs.search')} icon="general.search" iconColor="gray_600" />
      </ToolbarContaier>
      <WhiteSpace height={'12px'} />
      <NotificationsContainer>
        <table style={{width: 'calc(100% - 48px)'}}>
          <tbody>
            {pushNotifications && (searchResults ? searchResults : pushNotifications).map((notification) => (
              <tr key={notification.sentDate}>
                <td>
                  <NotificationContainer>
                    <NotificationTitle>{notification.pushTitle}</NotificationTitle>
                    <NotificationBody>{notification.pushBody}</NotificationBody>
                    <NotificationTime>{new Date(notification.sentDate * 1000).toISOString().split('.')[0].split('T').join(' · ').split('.')[0].replace(/-/g, '.')}</NotificationTime>
                  </NotificationContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </NotificationsContainer>
    </>
  )
}