import styled from "styled-components"

const InputElement = styled.input<{ $width: string }>`
  background: none;
  border: 1px solid ${props => props.theme.colors.gray_300};
  border-radius: 8px;
  padding: 12px;
  width: ${props => props.$width};
  outline: none;
`

export default function Input({ width, placeholder, value, onInput }: { width: string, placeholder: string, value?: string, onInput: (value: string) => void }) {
  return (
    <InputElement $width={width} placeholder={placeholder} value={value} onInput={(e) => onInput((e.target as HTMLInputElement).value)} />
  )
}