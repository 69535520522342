export const lightTheme = {
  colors: {
    primary: '#4AB1FC',
    primaryActive: '#239FF9',
    primaryLight: '#ECF8FF',
    success: '#57D9BA',
    successActive: '#2BC19D',
    successLight: '#E7FFF9',
    danger: '#FD4E4E',
    dangerActive: '#ED3C3C',
    dangerLight: '#FFEEEE',
    info: '#57C9D9',
    infoActive: '#26B4C7',
    infoLight: '#EAFCFF',
    warning: '#FDD85D',
    warningActive: '#F8C92C',
    warningLight: '#FFF8E3',
    white: '#FFFFFF',
    black: '#000000',
    whiteActive: '#F5F8FA',
    dark: '#181C32',
    background: '#F9F9F9',
    gray_100: '#F5F8FA',
    gray_200: '#EFF2F5',
    gray_300: '#E4E6EF',
    gray_400: '#B5B5C3',
    gray_500: '#A1A5B7',
    gray_600: '#7E8299',
    gray_700: '#5E6278',
    gray_800: '#3F4254',
    gray_900: '#181C32'
  }
}

export const darkTheme = {
  colors: {
    primary: '#0c69ac',
    primaryActive: '#1180d0',
    primaryLight: '#010d13',
    success: '#2ca085',
    successActive: '#47cdad',
    successLight: '#011812',
    danger: '#a80b0b',
    dangerActive: '#bb1b1b',
    dangerLight: '#0f0101',
    info: '#2c93a0',
    infoActive: '#42c1d1',
    infoLight: '#011113',
    warning: '#99780a',
    warningActive: '#ca9f12',
    warningLight: '#181201',
    white: '#000000',
    black: '#FFFFFF',
    whiteActive: '#05080a',
    dark: '#cdd0e5',
    background: '#101010',
    gray_100: '#181C32',
    gray_200: '#3F4254',
    gray_300: '#5E6278',
    gray_400: '#7E8299',
    gray_500: '#A1A5B7',
    gray_600: '#B5B5C3',
    gray_700: '#E4E6EF',
    gray_800: '#EFF2F5',
    gray_900: '#F5F8FA',
  }
}