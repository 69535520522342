import { useTranslation } from "react-i18next"
import PageTitle from "../components/PageTitle"
import styled from "styled-components"
import { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import WhiteSpace from "../components/Whitespace"
import { getDatabase, ref, child, get, set } from "firebase/database"
import fbConf from "../FirebaseConf"
import LoaderDots from "../components/LoaderDots"
import Modal from "../components/Modal"
import InputWithLabel from "../components/InputWithLabel"
import Button from "../components/Button"
import Icon from "../Icon"

interface AppVersionsType {
  iOS: {
    min: string,
    last: string,
    last_modified: number
  },
  Android: {
    min: string,
    last: string,
    last_modified: number
  }
}

const Container = styled.div`
  width: 20vw;
  background-color: ${props => props.theme.colors.white};
  margin: 12px;
  border-radius: 24px;
`

const Label = styled.p`
  font-weight: 400;
  color: ${props => props.theme.colors.gray_900};
  font-size: 16px;
  margin-top: 8px;
  margin-left: 24px;
`

const VersionContainer = styled.div<{ $theme: string, $bgRGBTop: string, $bgRGBBottom: string }>`
  background-image: ${props => `linear-gradient(rgb(${props.$bgRGBTop}), rgba(${props.$bgRGBBottom}))`};
  width: calc(100% - 48px - 48px);
  margin-left: 24px;
  padding: 24px;
  border-radius: 24px;
  display: flex;
  cursor: pointer;
  transition: transform .1s;

  &:active {
    transform: scale(.98);
  }
`

const VersionLabel = styled.p<{ $isWhite?: boolean }>`
  font-size: 12px;
  font-weight: 700;
  line-height: 0px;
  margin-top: 5px;
  margin-left: 16px;
  color: ${props => props.$isWhite ? props.theme.colors.white : props.theme.colors.black};
`

const Version = styled.p<{ $isWhite?: boolean }>`
  font-size: 24px;
  font-weight: 700;
  line-height: 0px;
  margin-top: 25px;
  margin-bottom: 0px;
  margin-left: 16px;
  color: ${props => props.$isWhite ? props.theme.colors.white : props.theme.colors.black};
`

const ModalContent = styled.div`
  padding-left: 88px;
  padding-right: 88px;
  padding-top: 135px;
  padding-bottom: 135px;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const ModalLabel = styled.p`
  font-weight: 400;
  color: ${props => props.theme.colors.gray_900};
  font-size: 16px;
  margin-top: 8px;
  width: 100%;
  text-align: center;
`

const LastModifiedLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  margin-left: 24px;
  line-height: 0px;
  margin-top: -5px;
  color: ${props => props.theme.colors.gray_600};
`

export default function AppVersion() {
  const { t } = useTranslation()
  const [theme] = useOutletContext<string>()

  const [appVersions, setAppVersions] = useState<AppVersionsType>()
  const [updateVersion, setUpdateVersion] = useState<string | null>() // null / ios / android
  const [minAppVersionInput, setMinAppVersionInput] = useState<string>('')
  const [latestAppVersionInput, setLatestAppVersionInput] = useState<string>('')

  useEffect(() => {
    async function fetchAppVersions() {
      const dbRef = ref(getDatabase(fbConf))
      const response = await get(child(dbRef, 'voltie/projectpreferences/mobile'))
      setAppVersions(response.val())
    }
    fetchAppVersions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleOpenModal(platform: string) {
    if (appVersions) {
      setMinAppVersionInput(platform == 'ios' ? appVersions.iOS.min : appVersions.Android.min)
      setLatestAppVersionInput(platform == 'ios' ? appVersions.iOS.last : appVersions.Android.last)
      setUpdateVersion(platform)
    }
  }

  async function handleSave() {
    if (minAppVersionInput != '' && latestAppVersionInput != '' && appVersions) {
      await set(ref(getDatabase(fbConf), `voltie/projectpreferences/mobile/${updateVersion == 'ios' ? 'iOS' : 'Android'}`), {
        last: latestAppVersionInput,
        min: minAppVersionInput,
        last_modified: Date.now()
      })

      setAppVersions({
        iOS: {
          min: updateVersion == 'ios' ? minAppVersionInput : appVersions.iOS.min,
          last: updateVersion == 'ios' ? latestAppVersionInput : appVersions.iOS.last,
          last_modified: updateVersion == 'ios' ? Date.now() : appVersions.iOS.last_modified
        },
        Android: {
          min: updateVersion == 'android' ? minAppVersionInput : appVersions.Android.min,
          last: updateVersion == 'android' ? latestAppVersionInput : appVersions.Android.last,
          last_modified: updateVersion == 'android' ?  Date.now() : appVersions.Android.last_modified
        }
      })

      setUpdateVersion(null)
    }
  }


  return (
    <>
      <PageTitle label={t('appVersion.updateAppVersion')} />
      <table>
        <tbody>
          <tr>
            <td>
              <Container>
                <Icon path="appversions.appleLogo" height="32px" marginLeft="24px" marginTop="24px" />
                <Label>{t('appVersion.updateIosAppVersion')}</Label>
                {appVersions ? (
                  <LastModifiedLabel>
                    {`${t('appVersion.lastModified')}: ${new Date(appVersions.iOS.last_modified).toString().split(' ').slice(1, 5).join(' ')}`}
                  </LastModifiedLabel>
                ) : (
                  <LastModifiedLabel>{t('appVersion.lastModified')}<LoaderDots /></LastModifiedLabel>
                )}
                <WhiteSpace height="1vw" />
                <VersionContainer $theme={theme} $bgRGBTop="12, 12, 12" $bgRGBBottom="61, 61, 61" onClick={() => handleOpenModal('ios')}>
                  <Icon path="appversions.minAppVersion" width="38px" height="38px" />
                  <div>
                    <VersionLabel $isWhite={true}>{t('appVersion.minAppVersion')}</VersionLabel>
                    {appVersions ? (
                      <>
                      <Version $isWhite={true}>{appVersions.iOS.min}</Version>
                      </>
                    ) : (
                      <Version $isWhite={true}><LoaderDots /></Version>
                    )}
                  </div>
                </VersionContainer>
                <WhiteSpace height=".5vw" />
                <VersionContainer $theme={theme} $bgRGBTop="255, 219, 104" $bgRGBBottom="255, 229, 147" onClick={() => handleOpenModal('ios')}>
                  <Icon path="appversions.latestAppVersion" width="38px" height="38px" />
                  <div>
                    <VersionLabel>{t('appVersion.latestAppVersion')}</VersionLabel>
                    {appVersions ? (
                      <Version>{appVersions.iOS.last}</Version>
                    ) : (
                      <Version><LoaderDots /></Version>
                    )}
                  </div>
                </VersionContainer>
                <WhiteSpace height="1vw" />
              </Container>
            </td>
            <td>
              <Container>
                <Icon path="appversions.androidLogo" height="32px" marginLeft="24px" marginTop="24px" />
                <Label>{t('appVersion.updateAndroidAppVersion')}</Label>
                {appVersions ? (
                  <LastModifiedLabel>
                    {`${t('appVersion.lastModified')}: ${new Date(appVersions.Android.last_modified).toString().split(' ').slice(1, 5).join(' ')}`}
                  </LastModifiedLabel>
                ) : (
                  <LastModifiedLabel>{t('appVersion.lastModified')}<LoaderDots /></LastModifiedLabel>
                )}
                <WhiteSpace height="1vw" />
                <VersionContainer $theme={theme} $bgRGBTop="221, 228, 237" $bgRGBBottom="235, 239, 245" onClick={() => handleOpenModal('android')}>
                  <Icon path="appversions.minAppVersion" width="38px" height="38px" />
                  <div>
                    <VersionLabel>{t('appVersion.minAppVersion')}</VersionLabel>
                    {appVersions ? (
                      <Version>{appVersions.Android.min}</Version>
                    ) : (
                      <Version><LoaderDots /></Version>
                    )}
                  </div>
                </VersionContainer>
                <WhiteSpace height=".5vw" />
                <VersionContainer $theme={theme} $bgRGBTop="134, 225, 175" $bgRGBBottom="168, 234, 198" onClick={() => handleOpenModal('android')}>
                  <Icon path="appversions.latestAppVersion" width="38px" height="38px" />
                  <div>
                    <VersionLabel>{t('appVersion.latestAppVersion')}</VersionLabel>
                    {appVersions ? (
                      <Version>{appVersions.Android.last}</Version>
                    ) : (
                      <Version><LoaderDots /></Version>
                    )}
                  </div>
                </VersionContainer>
                <WhiteSpace height="1vw" />
              </Container>
            </td>
          </tr>
        </tbody>
      </table>
      {updateVersion && (
        <Modal>
          <ModalContent>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setUpdateVersion(null)} />
            </ModalCloseButtonContainer>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    <Icon path={ updateVersion == 'ios' ? "appversions.appleLogo" : "appversions.androidLogo" } height="32px" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ModalLabel>{updateVersion == 'ios' ? t('appVersion.updateIosAppVersion') : t('appVersion.updateAndroidAppVersion')}</ModalLabel>
                  </td>
                </tr>
                <tr>
                  <td>
                    <WhiteSpace height='2vw' />
                    <InputWithLabel value={minAppVersionInput} onInput={(value) => setMinAppVersionInput(value)} label={`${t('appVersion.minAppVersion')} *`} width="100%" />
                    <WhiteSpace height='1vw' />
                    <InputWithLabel value={latestAppVersionInput} onInput={(value) => setLatestAppVersionInput(value)} label={`${t('appVersion.latestAppVersion')} *`} width="100%" />
                    <WhiteSpace height='2vw' />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button onclick={handleSave} label={t('appVersion.save')} width='100%' />
                  </td>
                </tr>
              </tbody>
            </table>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}