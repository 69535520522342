import styled from "styled-components"
import Icon from "../Icon"

const Input = styled.input<{ $width: string, $value: number, $min: number, $max: number }>`
  -webkit-appearance: none;
  appearance: none;
  width: ${props => props.$width};
  height: 6px;
  background: ${props => `linear-gradient(to right, #FFC20E ${(props.$value - props.$min) / (props.$max + props.$min) * 100}%, ${props.theme.colors.gray_300} ${props.$value / props.$max * 100}%)`};
  outline: none;
  cursor: pointer;
  border-radius: 8px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 28px;
    height: 28px;
    background: ${props => props.theme.colors.white};
    cursor: pointer;
    box-shadow: 0px 2px 8px 1px #00000033;
    border-radius: 28px;
  }

  &::-moz-range-thumb {
    width: 28px;
    height: 28px;
    background: ${props => props.theme.colors.white};
    cursor: pointer;
    box-shadow: 0px 2px 8px 1px #00000033;
    border-radius: 28px;
  }
`

const Dot = styled.p`
  font-size: 10px;
  color: ${props => props.theme.colors.gray_400};
  margin: 0px;
  margin-top: -5px;
  z-index: -1;
  position: absolute;
  margin-left: 2.5px;
`

const DotLabel = styled.p`
  font-family: 500;
  font-size: 13px;
  color: ${props => props.theme.colors.gray_500};
  margin: 0px;
  margin-top: 10px;
  margin-left: 2px;
  position: absolute;
`

const FirstLabel = styled.p`
  font-family: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 8px;
  float: left;
`

const LastLabel = styled.p`
  font-family: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: 8px;
  float: right;
`

const FirstImgContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-top: 8px;
  float: left;
`

const LastImgContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-top: 8px;
  float: right;
`

export default function Slider({ value, setValue, min, max, step, width, numberOfDots, dotLabels, firstLabel, lastLabel, firstImg, lastImg }: { value: number, setValue: (value: number) => void, min: number, max: number, step: number, width: string, numberOfDots: number, dotLabels?: string[], firstLabel?: string, lastLabel?: string, firstImg?: string, lastImg?: string }) {
  return (
    <>
      <Input type="range" min={min} max={max} step={step} value={value} $min={min} $max={max} $value={value} onChange={(e) => setValue(parseInt(e.target.value))} $width={width} />
      <table style={{ width: `calc(${width} + 20%)` }}>
        <tbody>
          <tr>
            {[...Array(numberOfDots)].map((a, i) => (
              <td key={`${a}${i}`} style={{ textAlign: 'center' }}>
                <Dot>•</Dot>
                {dotLabels && (
                  <DotLabel>{dotLabels[i] || ''}</DotLabel>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      {firstLabel && (
        <FirstLabel>{firstLabel}</FirstLabel>
      )}
      {lastLabel && (
        <LastLabel>{lastLabel}</LastLabel>
      )}
      {firstImg && (
        <FirstImgContainer>
          <Icon path={firstImg} width="100%" height="100%" />
        </FirstImgContainer>
      )}
      {lastImg && (
        <LastImgContainer>
          <Icon path={lastImg} width="100%" height="100%" />
        </LastImgContainer>
      )}
    </>
  )
}