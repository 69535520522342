import styled from "styled-components"
import { useEffect, useState } from "react"
import { useDetectClickOutside } from "react-detect-click-outside"
import Icon from "../Icon"

const Container = styled.div<{ $width: string }>`
  width: ${props => props.$width};
  position: relative;
`

const TitleContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.gray_300};
  padding: 12px;
  display: flex;
  position: relative;
  cursor: pointer;
`

const Label = styled.p<{ $isIcon: boolean }>`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  line-height: 0px;
  margin: 0px;
  margin-top: 4px;
  margin-left: ${props => props.$isIcon ? '12px' : '0px'};
`

const Value = styled.p<{ $isIcon: boolean, $isLabelles: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  line-height: 0px;
  margin: 0px;
  margin-top: ${props => props.$isLabelles ? '0px' : '16px'};
  margin-left: ${props => props.$isIcon ? '12px' : '0px'};
`

const ArrowContainer = styled.div<{ $isOpen: boolean, $isLabelles: boolean }>`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: ${props => props.$isLabelles ? '4px' : '16px'};
  transform: rotate(${props => props.$isOpen ? '180deg' : '0deg'});
`

const OptionsContainer = styled.div<{ $width: string, $isAbsolute: boolean }>`
  position: ${props => props.$isAbsolute ? 'absolute' : 'relative'};
  width: calc(${props => props.$width} + 24px);
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.gray_300};
  margin-top: 8px;
  background-color: ${props => props.theme.colors.white};
  z-index: 1000;
`

const OptionLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  margin: 16px;
  cursor: pointer;

  &:hover {
    margin-left: 18px;
  }
`

export default function Select({ width, icon, label, options, handleSelect, isAbsolute }: { theme: string, width: string, icon?: string, label?: string, options: string[], handleSelect: (value: string) => void, isAbsolute?: boolean }) {

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>(options[0])

  // Send the first element of options as selected to prevent value from being undefined if user does not change the default option
  useEffect(() => {
    handleSelect(options[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleClick(event: React.MouseEvent<HTMLElement>, value: string) {
    event.stopPropagation()
    setSelected(value)
    handleSelect(value)
    setIsOpen(false)
  }

  return (
    <>
      <Container $width={width}>
        <TitleContainer onClick={(e) => {e.stopPropagation(); setIsOpen(!isOpen)}} ref={useDetectClickOutside({ onTriggered: () => setIsOpen(false) })}>
          {icon && (
            <Icon path={icon} width="24px" height="24px" />
          )}
          <div>
            {label && (
              <Label $isIcon={icon ? true : false}>{label}</Label>
            )}
            <Value $isLabelles={!label} $isIcon={icon ? true : false}>{selected}</Value>
          </div>
          <ArrowContainer $isLabelles={!label} $isOpen={isOpen}>
            <Icon path="general.downArrow" width="100%" height="100%" />
          </ArrowContainer>
        </TitleContainer>
        {isOpen && (
          <OptionsContainer $isAbsolute={isAbsolute || false} $width={width}>
            {options && [...new Set(options)].map(option => (
              <OptionLabel key={option} onClick={(e) => handleClick(e, option)}>{option}</OptionLabel>
            ))}
          </OptionsContainer>
        )}
      </Container>
    </>
  )
}