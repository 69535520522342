import { useTranslation } from "react-i18next"
import WhiteSpace from "../../components/Whitespace"
import PageTitle from "../../components/PageTitle"
import ChargerType from "../../types/ChargerType"
import styled from "styled-components"
import { useEffect, useState } from "react"
import ChargeType from "../../types/ChargeType"
import Comm from "../../Comm"
import LoaderDots from "../../components/LoaderDots"
import Navigator from "../../components/Navigator"
import SlideToConfirm from "../../components/SlideToConfirm"
import dateToTimeAgoString from "../../utils/dateToTimeAgoString"
import { child, get, getDatabase, ref, set } from "firebase/database"
import fbConf from "../../FirebaseConf"
import { getAuth } from "firebase/auth"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import Modal from "../../components/Modal"
import Button from "../../components/Button"
import Icon from "../../Icon"
import React from "react"

const GeneralContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 18px;
  border-radius: 24px;
  position: relative;
  width: 62%;
`

const StatusContainer = styled.div<{ $bgColorTop: string, $bgColorBottom: string }>`
  position: relative;
  width: 12.5vw;
  height: 12.5vw;
  background-image: ${props => `linear-gradient(${props.$bgColorTop}, ${props.$bgColorBottom})`};
  border-radius: 16px;
  user-select: none;
  -webkit-user-select: none;
`

const StatusTitle = styled.span<{ $bgColor: string, $fontColor: string }>`
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.$fontColor};
  background-color: ${props => props.$bgColor};
  border: 1px solid white;
  border-radius: 40px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  transform: translate(-50%, -50%);
  left: 50%;
`

const StatusOfflineLabel = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #718096;
  width: 100%;
  margin: 0px;
  padding-top: 20px;
  text-align: center;
`

const StatusLabel = styled.p<{ $color: string }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.$color};
  margin: 0px;
`

const ChargerNameLabel = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  left: 15vw;
  top: 0px;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`

const ChargerNameValue = styled.p`
  font-weight: 700;
  font-size: 28px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  padding-right: 30px;
  left: 15vw;
  top: 18px;
`

const UserIdEmailLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  position: absolute;
  left: 35vw;
  top: 8px;
`

const UserIdEmailValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  position: absolute;
  left: 35vw;
  top: 26px;
`

const GeneralDetailsTabel = styled.table`
  position: absolute;
  left: calc(12.5vw + 18px + 18px);
  top: 130px;
  width: calc(100% - 12.5vw - 18px - 18px);
`

const GeneralDetailsDivider = styled.div`
  position: absolute;
  left: calc(12.5vw + 18px + 18px);
  top: 195px;
  width: calc(100% - 12.5vw - 18px - 18px - 18px);
  height: 2px;
  background-image: linear-gradient(270deg, #E2594B 0%, #2A6FF4 100%);
`

const GeneralDetailLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  margin: 0px;
`

const GeneralDetailValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const ConnectionContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 18px;
  border-radius: 24px;
  position: relative;
`

const ConnectionTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
`

const ConnectionTableCell = styled.td`
  display: flex;
  width: 33.3%;
  margin-top: 12px;
`

const ConnectionLabel = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  margin: 0px;
  width: 10vw;
`

const ConnectionValue = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
  margin-top: -1px;
`

const ConnectionTableDivider = styled.p<{ $left: string }>`
  position: absolute;
  height: 134px;
  width: 0px;
  border: 1px solid ${props => props.theme.colors.gray_200};
  left: ${props => props.$left};
  top: 60px;
`

const StatCardTable = styled.table`
  width: 35%;
  position: absolute;
  right: 0px;
`

const StatCard = styled.div<{ $color: string }>`
  border-radius: 24px;
  padding: 24px;
  background-color: ${props => props.theme.colors[props.$color]};
  display: flex;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  height: calc(((12.5vw - 24px - 36px) / 2) - 10px);
`

const StatCardIconContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 18px;
  height: 18px;
  padding: 7px;
  border-radius: 100px;
  position: absolute;
  right: 24px;
  top: 24px;
`

const StatCardTitle = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
`

const StatCardValue = styled.p<{ $white?: boolean }>`
  font-weight: 700;
  font-size: 24px;
  color: ${props => props.$white ? props.theme.colors.white : props.theme.colors.dark};
  margin: 0px;
  margin-top: calc(((12.5vw - 24px - 36px) / 2) - 10px - 36px);
`

const StatCardUnit = styled.span`
  font-weight: 500;
  font-size: 15px;
  padding-left: 6px;
`

const CommentContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 18px;
  border-radius: 24px;
  position: relative;
`

const CommentTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.theme.colors.gray_900};
`

const SmallModalContainer = styled.div`
  padding: 24px;
`

const SmallModalLabel = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
`

const SmallModalButtonContainer = styled.div`
  width: 15vw;
  display: flex;
`

const ModalCloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 26px;
`

const AddCommentTextArea = styled.textarea`
  width: calc(100% - 4px);
  height: 120px;
  border-radius: 4px;
  border: none;
  outline: none;
`

const CommentMessageText = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_500};
  max-width: 100%;
  margin: 0px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
`

const CommentAuthorText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const CommentDateText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_400};
  margin: 0px;
`

export default function GeneralInformation({ charger }: { charger: ChargerType }) {
  const { t } = useTranslation()

  const [charges, setCharges] = useState<ChargeType[]>()
  const [chargerGroup, setChargerGroup] = useState<string>()

  const [allChargedEnergy, setAllChargedEnergy] = useState<number>()
  const [allChargetime, setAllChargetime] = useState<number>()
  const [evseState, setEvseState] = useState<{ state: number, chg_on: boolean } | null>()
  const [chargerComments, setChargerComments] = useState<{ [key: string]: { author: string, comment: string } }>()
  const [isShowingNewCommentModal, setIsShowingNewCommentModal] = useState<boolean>(false)
  const [newCommentInputValue, setNewCommentInputValue] = useState<string>()
  const [chargerDetails, setChargerDetails] = useState<{ ipAddress: string, macAddress: string, connection: string, dlmState: string, midMeterState: string, lifetimeRealySwitches: number }>()

  async function fetchCharges() {
    const response = await Comm('/api-cdr/cdrs/last', {
      headers: { "Charger_ID": charger.chargerId }
    })
    const result = await response.json()
    result.reverse()
    setCharges(result)


    // Calculate stats
    let chargedEnergy = 0
    let chargedTime = 0
    for (const charge of result) {
      chargedEnergy += charge.chg_energy
      chargedTime += charge.chg_time
    }

    setAllChargedEnergy(chargedEnergy)
    setAllChargetime(chargedTime)
  }

  async function fetchEvseState() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 3 })
    })

    if (response.ok) {
      const result = await response.json()
      console.log(result)
      setEvseState({ state: result.evse_state, chg_on: result.chg_on })
    }
  }

  async function fetchChargerComments() {
    const dbRef = ref(getDatabase(fbConf))
    const response = await get(child(dbRef, 'voltie/updategroups'))
    const result = response.val()

    for (const group in result) {
      for (const chargerInGroup in result[group].chargers) {
        if (chargerInGroup == charger.chargerId) {
          console.log(chargerInGroup)
          console.log(result[group].chargers[chargerInGroup])
          setChargerGroup(group)
          if (result[group].chargers[chargerInGroup].comments) {
            setChargerComments(result[group].chargers[chargerInGroup].comments)
          }
        }
      }
    }
  }

  async function fetchChargerDetails() {
    const CMD_GET_PRODUCT_NFO_response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 12 })
    })
    const CMD_GET_PRODUCT_NFO_result = await CMD_GET_PRODUCT_NFO_response.json()

    const CMD_GET_CONNECTION_STAT_response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 22 })
    })
    const CMD_GET_CONNECTION_STAT_result = await CMD_GET_CONNECTION_STAT_response.json()

    let connectionMode = ''
    switch (CMD_GET_CONNECTION_STAT_result.active_nwi) {
      case 1:
      case 2:
        connectionMode = 'ethernet'
        break;
      case 3:
        connectionMode = 'wifi'
        break;
      case 4:
      case 5:
      case 6:
        connectionMode = 'gsm'
        break;
      default:
        connectionMode = 'unknown'
        break;
    }

    setChargerDetails({
      ipAddress: connectionMode == 'ethernet' ? CMD_GET_CONNECTION_STAT_result.ethernet.ip_address : connectionMode == 'wifi' ? CMD_GET_CONNECTION_STAT_result.wifi.ip : t(`charger.unknown`),
      macAddress: connectionMode == 'ethernet' ? CMD_GET_CONNECTION_STAT_result.ethernet.mac_address : connectionMode == 'wifi' ? CMD_GET_CONNECTION_STAT_result.wifi.mac : t(`charger.unknown`),
      connection: t(`charger.${connectionMode}`),
      dlmState: CMD_GET_PRODUCT_NFO_result.dlm_state.toString(),
      midMeterState: CMD_GET_PRODUCT_NFO_result.mid_state.toString(),
      lifetimeRealySwitches: CMD_GET_PRODUCT_NFO_result.lifetime.lifetime_switch
    })
  }

  useEffect(() => {
    async function fetchCharger() {
      await fetchCharges()
      await fetchChargerComments()
      await fetchEvseState()
      if (charger.online) {
        fetchChargerDetails()
      }
    }
    fetchCharger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function secondsToHumanReadable(seconds: number) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds - (hours * 3600)) / 60)

    if (hours.toString() == '0') {
      return `${hours}${t('charger.m')}`
    } else {
      return `
        ${hours}${t('charger.h')} 
        ${minutes}${t('charger.m')} 
      `
    }
  }

  function chargerDlmAndMidStateCodeToMeaning(code: string) {
    switch (parseInt(code)) {
      case 0: return 'UNAVAILABLE_ERROR'
      case 1: return 'UNAVAILABLE'
      case 2: return 'CONNECTED_NOT_IN_USE'
      case 3: return 'CONNECTED_IN_USE'
    }
  }

  async function startCharging() {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 5 })
    })
    setEvseState(null)
    setTimeout(fetchEvseState, 3000)
  }

  async function stopCharging() {
    await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": charger.chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 6 })
    })
    setEvseState(null)
    setTimeout(fetchEvseState, 3000)
  }

  async function addChargerComment() {
    await set(ref(getDatabase(fbConf), `voltie/updategroups/${chargerGroup}/chargers/${charger.chargerId}/comments/${Date.now()}`), {
      author: getAuth(fbConf).currentUser?.email,
      comment: newCommentInputValue || ''
    })
    setIsShowingNewCommentModal(false)
    setNewCommentInputValue('')
    fetchChargerComments()
  }

  return (
    <>
      {isShowingNewCommentModal && (
        <Modal>
          <SmallModalContainer>
            <ModalCloseButtonContainer>
              <Icon path="general.x" width="20px" height="20px" onClick={() => setIsShowingNewCommentModal(false)} />
            </ModalCloseButtonContainer>
            <SmallModalLabel>{t('charger.addNewComment')}</SmallModalLabel>
            <WhiteSpace height="16px" />
            <AddCommentTextArea value={newCommentInputValue} onInput={(e) => setNewCommentInputValue((e.target as HTMLInputElement).value)}></AddCommentTextArea>
            <WhiteSpace height="8px" />
            <SmallModalButtonContainer>
              <Button onclick={addChargerComment} label={t('charger.add')} width="calc(50% - 8px)" bgcolor="#4AB1FC" labelcolor="white" />
              <WhiteSpace width="16px" />
              <Button onclick={() => setIsShowingNewCommentModal(false)} label={t('charger.cancel')} width="calc(50% - 8px)" />
            </SmallModalButtonContainer>
          </SmallModalContainer>
        </Modal>
      )}
      <WhiteSpace height={'12px'} />
      <PageTitle label={t('charger.generalInformation')} />
      <WhiteSpace height={'12px'} />

      <div style={{ display: 'flex' }}>
        <GeneralContainer>

          {/* OFFLINE */}
          {!charger.online && (
            <StatusContainer $bgColorTop="#E3E9F1" $bgColorBottom="#E3E9F1">
              <StatusTitle $bgColor="#E3E9F1" $fontColor="#7E8299">OFFLINE</StatusTitle>
              <StatusOfflineLabel>{dateToTimeAgoString(new Date(charger.lastPresence.replace(/\./g, '-')))}</StatusOfflineLabel>
              <WhiteSpace height="20px" />
              <Icon path="chargers.chargerStatusOffline" width="58%" marginLeft="calc((100% - 58%) / 2)" />
            </StatusContainer>
          )}

          {/* LOADING */}
          {charger.online && !evseState && (
            <StatusContainer $bgColorTop="#E3E9F1" $bgColorBottom="#E3E9F1">
              <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
                <WhiteSpace height="40%" />
                <span style={{ fontWeight: "700", fontSize: "24px" }}>
                  <LoaderDots />
                </span>
              </div>
            </StatusContainer>
          )}

          {/* NOT CONNECTED */}
          {charger.online && evseState?.state == 1 && (
            <StatusContainer $bgColorTop="#57D9BA" $bgColorBottom="#09BCA6">
              <StatusTitle $bgColor="#57D9BA" $fontColor="#FFFFFF">ONLINE</StatusTitle>
              <WhiteSpace height="30px" />
              <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <Icon path="chargers.unplugged" width="16px" height="16px" />
                <WhiteSpace width="8px" />
                <StatusLabel $color="#4F8378">Car unplugged</StatusLabel>
              </div>
              <WhiteSpace height="20px" />
              <Icon path="chargers.chargerStatusOnline" width="58%" marginLeft="calc((100% - 58%) / 2)" />
            </StatusContainer>
          )}

          {/* CONNECTED - NOT AVAILABLE TO CHARGE */}
          {charger.online && evseState?.state == 2 && (
            <StatusContainer $bgColorTop="#57D9BA" $bgColorBottom="#09BCA6">
              <StatusTitle $bgColor="#57D9BA" $fontColor="#FFFFFF">ONLINE</StatusTitle>
              <WhiteSpace height="30px" />
              <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <Icon path="chargers.pluggedIn" width="16px" height="16px" />
                <WhiteSpace width="8px" />
                <StatusLabel $color="#181C32">Car plugged in</StatusLabel>
              </div>
              <WhiteSpace height="20px" />
              <Icon path="chargers.chargerStatusOnline" width="58%" marginLeft="calc((100% - 58%) / 2)" />
            </StatusContainer>
          )}

          {/* CONNECTED - AVAILABLE TO CHARGE */}
          {charger.online && (evseState?.state == 3 || evseState?.state == 4) && !evseState.chg_on && (
            <StatusContainer $bgColorTop="#57D9BA" $bgColorBottom="#09BCA6">
              <StatusTitle $bgColor="#57D9BA" $fontColor="#FFFFFF">ONLINE</StatusTitle>
              <WhiteSpace height="20px" />
              <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <Icon path="chargers.pluggedIn" width="16px" height="16px" />
                <WhiteSpace width="8px" />
                <StatusLabel $color="#181C32">Car plugged in</StatusLabel>
              </div>
              <WhiteSpace height="10px" />
              <Icon path="chargers.chargerStatusOnline" width="58%" marginLeft="calc((100% - 58%) / 2)" />
              <div style={{ display: 'flex', position: "absolute", width: "100%", bottom: "3%" }}>
                <WhiteSpace width='3%' />
                <SlideToConfirm width='94%' label="Slide to START charging" onConfirm={startCharging} icon="chargers.power" bgColorLeft="#2A6FF4" bgColorRight="#56F2DF" />
              </div>
            </StatusContainer>
          )}

          {/* CHARGING */}
          {charger.online && (evseState?.state == 3 || evseState?.state == 4) && evseState.chg_on && (
            <StatusContainer $bgColorTop="#2B71F5" $bgColorBottom="#50D7DF">
              <StatusTitle $bgColor="#2B71F5" $fontColor="#FFFFFF">CHARGING</StatusTitle>
              <WhiteSpace height="35px" />
              <Icon path="chargers.chargerStatusCharging" width="58%" marginLeft="calc((100% - 58%) / 2)" />
              <div style={{ display: 'flex', position: "absolute", width: "100%", bottom: "3%" }}>
                <WhiteSpace width='3%' />
                <SlideToConfirm width='94%' label="Slide to STOP charging" onConfirm={stopCharging} icon="chargers.power" bgColorLeft="#2A6FF4" bgColorRight="#E2594B" />
              </div>
            </StatusContainer>
          )}

          {/* BLOCKED */}
          {charger.online && evseState?.state == 18 && (
            <StatusContainer $bgColorTop="#505369" $bgColorBottom="#7F8291">
              BLOCKED
            </StatusContainer>
          )}

          {/* ERROR */}
          {charger.online && evseState && evseState.state != 1 && evseState.state != 2 && evseState.state != 3 && evseState.state != 4 && evseState.state != 18 && (
            <StatusContainer $bgColorTop="#EB5172" $bgColorBottom="#FD4E4E">
              <StatusTitle $bgColor="#EB5172" $fontColor="#FFFFFF">ERROR</StatusTitle>
              <WhiteSpace height="40px" />
              <Icon path="chargers.chargerStatusError" width="58%" marginLeft="calc((100% - 58%) / 2)" />
              <WhiteSpace height="20px" />
              <Navigator to={`/chargers/${charger.chargerId}/logs`}>
                <StatusLabel style={{ width: "100%", textAlign: "center" }} $color="#FFFFFF">Check LOGs</StatusLabel>
              </Navigator>
            </StatusContainer>
          )}


          <ChargerNameLabel>{t('charger.chargerName')}</ChargerNameLabel>
          <ChargerNameValue>{charger.localName}</ChargerNameValue>
          <UserIdEmailLabel>{t('charger.userIdEmail')}</UserIdEmailLabel>
          <UserIdEmailValue>
            <Navigator newTab to={`https://console.firebase.google.com/project/voltie-charger/database/voltie-charger-default-rtdb/data/~2Fvoltie~2Fchargerusers~2F${charger.userId}`}>
              {charger.userId}<Icon path="general.forwardArrow" width="16px" height="16px" marginLeft="5px" />
            </Navigator>
            <br />{charger.userEmail}
          </UserIdEmailValue>
          <GeneralDetailsTabel>
            <tbody>
              <tr>
                <td>
                  <GeneralDetailLabel>{t('charger.group')}</GeneralDetailLabel>
                  <GeneralDetailValue>{chargerGroup || '-'}</GeneralDetailValue>
                </td>
                <td>
                  <GeneralDetailLabel>{t('charger.chargerId')}</GeneralDetailLabel>
                  <GeneralDetailValue>{charger.chargerId}</GeneralDetailValue>
                </td>
                <td>
                  <GeneralDetailLabel>{t('charger.registrationDate')}</GeneralDetailLabel>
                  <GeneralDetailValue>{charger.registrationDate}</GeneralDetailValue>
                </td>
                <td>
                  <GeneralDetailLabel>{t('charger.serialNumber')}</GeneralDetailLabel>
                  <GeneralDetailValue>{charger.serialNumber == ' ' ? '-' : charger.serialNumber || '-'}</GeneralDetailValue>
                </td>
              </tr>
            </tbody>
          </GeneralDetailsTabel>
          <GeneralDetailsDivider />
        </GeneralContainer>
        <StatCardTable>
          <tbody>
            <tr>
              <td style={{ width: '50%' }}>
                <StatCard $color="dark">
                  <StatCardIconContainer>
                    <Icon path="chargers.power" width="100%" height="100%" />
                  </StatCardIconContainer>
                  <div>
                    <StatCardTitle $white>{t('charger.allCharges')}</StatCardTitle>
                    <StatCardValue $white>{charges ? charges.length : <LoaderDots />}<StatCardUnit>{t('charger.pcs')}</StatCardUnit></StatCardValue>
                  </div>
                </StatCard>
              </td>
              <td style={{ width: '50%' }}>
                <StatCard $color="warning">
                  <StatCardIconContainer>
                    <Icon path="chargers.energy" width="100%" height="100%" color="warning" />
                  </StatCardIconContainer>
                  <div>
                    <StatCardTitle>{t('charger.allChargedEnergy')}</StatCardTitle>
                    <StatCardValue>{typeof allChargedEnergy === 'number' ? allChargedEnergy.toFixed(2) : <LoaderDots />}<StatCardUnit>kWh</StatCardUnit></StatCardValue>
                  </div>
                </StatCard>
              </td>
            </tr>
            <tr>
              <td>
                <WhiteSpace height="8px" />
              </td>
            </tr>
            <tr>
              <td style={{ width: '50%' }}>
                <StatCard $color="primary">
                  <StatCardIconContainer>
                    <Icon path="chargers.time" width="100%" height="100%" color="primary" />
                  </StatCardIconContainer>
                  <div>
                    <StatCardTitle>{t('charger.allChargetime')}</StatCardTitle>
                    <StatCardValue>{typeof allChargetime === 'number' ? secondsToHumanReadable(allChargetime) : <LoaderDots />}</StatCardValue>
                  </div>
                </StatCard>
              </td>
              <td style={{ width: '50%' }}>
                <StatCard $color="dark">
                  <StatCardIconContainer>
                    <Icon path="chargers.power" width="100%" height="100%" />
                  </StatCardIconContainer>
                  <div>
                    <StatCardTitle $white>{t('charger.realySwitches')}</StatCardTitle>
                    <StatCardValue $white>{charger.online ? chargerDetails ? chargerDetails.lifetimeRealySwitches : <LoaderDots /> : '-'}<StatCardUnit>{t('charger.pcs')}</StatCardUnit></StatCardValue>
                  </div>
                </StatCard>
              </td>
            </tr>
          </tbody>
        </StatCardTable>
      </div>
      <WhiteSpace height="18px" />
      <div style={{ overflow: "scroll", height: "calc(90vh - 12.5vw - 130px)" }}>
        <ConnectionContainer>
          <ConnectionTitle>{t('charger.connection')}</ConnectionTitle>
          <ConnectionTableDivider $left="31%" />
          <ConnectionTableDivider $left="64%" />
          <table style={{ width: '100%' }}>
            <tbody>
              <tr style={{ display: 'flex' }}>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.ipAddress')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails?.ipAddress || <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.dlmState')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails ? chargerDlmAndMidStateCodeToMeaning(chargerDetails.dlmState) : <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.software')}</ConnectionLabel>
                  <ConnectionValue>{charger.swVersion}</ConnectionValue>
                </ConnectionTableCell>
              </tr>
              <tr style={{ display: 'flex' }}>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.macAddress')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails?.macAddress || <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.midMeterState')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails ? chargerDlmAndMidStateCodeToMeaning(chargerDetails.midMeterState) : <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.firmware')}</ConnectionLabel>
                  <ConnectionValue>{charger.fwVersion}</ConnectionValue>
                </ConnectionTableCell>
              </tr>
              <tr style={{ display: 'flex' }}>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.connection')}</ConnectionLabel>
                  <ConnectionValue>{charger.online ? chargerDetails?.connection || <LoaderDots /> : '-'}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell></ConnectionTableCell>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.operatingSystem')}</ConnectionLabel>
                  <ConnectionValue>{charger.osVersion}</ConnectionValue>
                </ConnectionTableCell>
              </tr>
              <tr style={{ display: 'flex' }}>
                <ConnectionTableCell>
                  <ConnectionLabel>{t('charger.serverConnection')}</ConnectionLabel>
                  <ConnectionValue>{charger.lastPresence}</ConnectionValue>
                </ConnectionTableCell>
                <ConnectionTableCell></ConnectionTableCell>
                <ConnectionTableCell></ConnectionTableCell>
              </tr>
            </tbody>
          </table>
        </ConnectionContainer>
        <WhiteSpace height="18px" />
        <CommentContainer>
          <CommentTitle>{t('charger.comments')}</CommentTitle>
          <WhiteSpace height="8px" />
          <div style={{ position: "absolute", top: '24px', right: '24px' }}>
            <ButtonWithIcon icon="general.plus" label={t('charger.newComment')} onClick={() => setIsShowingNewCommentModal(true)} backgroundColor="dark" iconColor="white" />
          </div>
          {chargerComments && Object.keys(chargerComments).sort().reverse().map((comment) => <React.Fragment key={comment}>
            <div style={{ display: 'flex' }}>
              <CommentAuthorText>{chargerComments[comment].author || '-'}</CommentAuthorText>
              <WhiteSpace width="4px" />
              <CommentDateText>•</CommentDateText>
              <WhiteSpace width="4px" />
              <CommentDateText>{`${new Date(parseInt(comment)).toLocaleDateString('hu').replace(/\. /g, '.') || '-'} ${new Date(parseInt(comment)).toLocaleTimeString('hu') || '-'}`}</CommentDateText>
            </div>
            <WhiteSpace height="8px" />
            <CommentMessageText>{chargerComments[comment].comment || '-'}</CommentMessageText>
            <WhiteSpace height="32px" />
          </React.Fragment>)}
        </CommentContainer>
      </div>
    </>
  )
}